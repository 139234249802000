import React, { useState, useContext } from "react"
import styled from "styled-components"
import SplitLayout from "./SplitLayout"
import { graphql, useStaticQuery } from "gatsby"
import RegisterFormik from "./RegisterFormik"
import Error from "./Error"
import AuthContext from "../../context/AuthContext"
import Logo from "../../zzz/atoms/logo"
import ShowOnButton from "./ShowOnButton"
import BlurLoader from "../../zzz/organisms/blurLoader"
import { LinkWrapper as Link } from "../../utils/linkWrapper"

// ======================
// 	🧱🧱 COMPONENT 🧱🧱
// ======================

const Register = () => {
  const { loggedInState } = useContext(AuthContext)

  const { file } = useStaticQuery(IMAGE)
  const fluid = file.childImageSharp.fluid

  const [loading, setLoading] = useState(false)
  const [apiError, setApiError] = useState(false)

  return (
    <SplitLayout direction="end" fluid={fluid} Logo={Logo}>
      <Skinny>
        <BlurLoader
          loading={loading || loggedInState === null}
          message="Working"
          shade="light"
        >
          <Link to="/">
            <Logo />
          </Link>
          <Title>Register</Title>
          <RegisterFormik
            loading={loading}
            setLoading={setLoading}
            setApiError={setApiError}
          />
          {apiError && <Error error={apiError.content} />}
          <Extra>
            Back to <Link to="/login/">Login</Link>
          </Extra>
          <ShowOnButton />
        </BlurLoader>
      </Skinny>
    </SplitLayout>
  )
}
// ======================
// 	💅🏼💅🏼 STYLES 💅🏼💅🏼
// ======================
const Extra = styled.div`
  color: white;
  text-align: center;
  margin: 20px 0;
  a {
    color: white;
    text-decoration: underline;
  }
`
const Title = styled.h3`
  font-size: 39px;
  color: white;
  text-align: center;
  margin: 20px 0;
`
const Skinny = styled.div`
  max-width: 350px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  svg {
    display: none;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      display: block;
    }
  }

  .show-on {
    margin-top: 20px;
  }
`

const IMAGE = graphql`
  {
    file(relativePath: { eq: "making-bed.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Register
